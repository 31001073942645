/* The idea of this Enum is to keep a mapping of colors used to represent the different vehicle status.
This is needed because the Charts use the color scheme from uikit definition (defined here
https://uikit.developers.rio.cloud/#charts/chartColors), and for the other elements we need to use the backgrounds
defined as classes by uikit (defined here: https://uikit.developers.rio.cloud/#design/backgroundColors).
The way used to get these proper mappings was to compare the output color codes.
*/
export const ColorMappings = {
    CRITICAL: {
        backgroundColorClassName: 'bg-danger',
        chartColorName: 'brand-danger',
    },
    WARNING: {
        backgroundColorClassName: 'bg-warning',
        chartColorName: 'brand-warning',
    },
    OK: {
        backgroundColorClassName: 'bg-primary',
        chartColorName: 'gray-darkest', // note: this is not the exact same color as 'bg-primary' but is very close
    },
    UNKNOWN: {
        backgroundColorClassName: 'bg-gray',
        chartColorName: 'gray',
    },
};
