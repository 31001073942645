import React from 'react';
import { VehicleStatusData } from '../../generated/GeneratedApiTypes';
import VehicleDataTabComponent from './VehicleDataTabComponent';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { useIntl } from 'react-intl';
import { VehicleStatusDataSectionKey } from './DataTabsSettingsDialogComponent';

export type VehicleDataTabsComponentProps = {
    isLoading: boolean;
    vehiclesStatusData: VehicleStatusData[];
    onClickVehicleNameAndVinAndLicensePlate: Function;
    onClickCustomerName: Function;
    onClickVehicleInfoSection: Function;
    onClickMaintenanceSection: Function;
    onClickBrakeLiningsSection: Function;
    onClickWarningMessagesSection: Function;
    onClickDtcDm1Section: Function;
    onClickDtcDm4Section: Function;
    highlightAssetId?: string;
    clearHighlight: Function;
    selectedDataTabsSections: VehicleStatusDataSectionKey[];
};

const DataTabsSkeletonScreen = () => {
    const dataTabsSkeleton = [];
    for (let i = 0; i < 10; i++) {
        dataTabsSkeleton.push(
            <div key={`skeleton-key-${i}`}>
                <div className={'margin-top-20'} />
                <ContentLoader height={122} />
            </div>,
        );
    }
    return (
        <div data-testid={'vehicles-data-tabs-skeleton-screen'} className={'display-flex flex-column flex-1-0'}>
            {dataTabsSkeleton.map((item) => item)}
        </div>
    );
};

const DataTabsNoResults = () => {
    const intl = useIntl();
    return (
        <div className="display-flex justify-content-center max-width-100pct">
            <div className="display-flex flex-column align-items-center text-center padding-25">
                <div className="text-size-h2 margin-bottom-20">
                    <span className="rioglyph text-color-light rioglyph-looking-glass-man text-size-300pct" />
                </div>
                <div className="text-size-h3 text-size-h2-sm line-height-125rel text-color-dark text-medium">
                    {intl.formatMessage({ id: 'vehicle_status.datatabs.no_results.title' })}
                </div>
                <div className="text-color-dark margin-top-15">
                    {intl.formatMessage({ id: 'vehicle_status.datatabs.no_results.description' })}
                </div>
            </div>
        </div>
    );
};

const VehicleDataTabsComponent = (props: VehicleDataTabsComponentProps) => {
    const {
        isLoading,
        vehiclesStatusData,
        onClickVehicleNameAndVinAndLicensePlate,
        onClickCustomerName,
        onClickVehicleInfoSection,
        onClickMaintenanceSection,
        onClickBrakeLiningsSection,
        onClickWarningMessagesSection,
        onClickDtcDm1Section,
        onClickDtcDm4Section,
        highlightAssetId,
        clearHighlight,
        selectedDataTabsSections,
    } = props;
    if (isLoading && vehiclesStatusData.length === 0) {
        return <DataTabsSkeletonScreen />;
    }
    const renderedDataTabs =
        vehiclesStatusData.length === 0 ? (
            <DataTabsNoResults />
        ) : (
            vehiclesStatusData.map((vehicleStatusData) =>
                renderVehicleDataTab(
                    vehicleStatusData,
                    onClickVehicleNameAndVinAndLicensePlate,
                    onClickCustomerName,
                    onClickVehicleInfoSection,
                    onClickMaintenanceSection,
                    onClickBrakeLiningsSection,
                    onClickWarningMessagesSection,
                    onClickDtcDm1Section,
                    onClickDtcDm4Section,
                    highlightAssetId === vehicleStatusData.asset_id,
                    clearHighlight,
                    selectedDataTabsSections,
                ),
            )
        );
    return (
        <>
            {isLoading && (
                <div className={'position-relative height-0'}>
                    <Spinner />
                </div>
            )}
            <div className={`display-flex flex-column flex-1-0 ${isLoading && 'opacity-30 pointer-events-none'}`}>
                {renderedDataTabs}
            </div>
        </>
    );
};

const renderVehicleDataTab = (
    vehicleData: VehicleStatusData,
    onClickVehicleNameAndVinAndLicensePlate: Function,
    onClickCustomerName: Function,
    onClickVehicleInfoSection: Function,
    onClickMaintenanceSection: Function,
    onClickBrakeLiningsSection: Function,
    onClickWarningMessagesSection: Function,
    onClickDtcDm1Section: Function,
    onClickDtcDm4Section: Function,
    highlight: boolean,
    clearHighlight: Function,
    selectedDataTabsSections: VehicleStatusDataSectionKey[],
) => {
    const {
        asset_id,
        vin,
        customer,
        type,
        name,
        license_plate,
        device_type,
        total_appointments,
        total_workshop_actions,
        total_vehicle_notes,
        next_workshop_appointment,
        total_components_overdue,
        total_components_almost_due,
        total_brake_linings_critical,
        total_brake_linings_warnings,
        total_active_dm1_dtcs_critical,
        total_active_dm1_dtcs_warning,
        total_active_dm1_dtcs_other,
        total_active_dm4_dtcs_critical,
        total_active_dm4_dtcs_warning,
        total_active_dm4_dtcs_other,
        total_warning_messages_critical,
        total_warning_messages_warning,
        total_warning_messages_info,
        last_maintenance_message_timestamp,
        sync_status,
        status,
    } = vehicleData;
    return (
        <VehicleDataTabComponent
            key={`key_${asset_id}`}
            type={type}
            name={name}
            vin={vin}
            customerName={customer}
            licensePlate={license_plate}
            deviceType={device_type}
            totalAppointments={total_appointments}
            totalWorkshopActions={total_workshop_actions}
            totalVehicleNotes={total_vehicle_notes}
            nextWorkshopAppointment={next_workshop_appointment}
            totalComponentsOverdue={total_components_overdue}
            totalComponentsAlmostDue={total_components_almost_due}
            totalBrakeLiningsCritical={total_brake_linings_critical}
            totalBrakeLiningsWarning={total_brake_linings_warnings}
            totalActiveDm1DtcsCritical={total_active_dm1_dtcs_critical}
            totalActiveDm1DtcsWarning={total_active_dm1_dtcs_warning}
            totalActiveDm1DtcsOther={total_active_dm1_dtcs_other}
            totalActiveDm4DtcsCritical={total_active_dm4_dtcs_critical}
            totalActiveDm4DtcsWarning={total_active_dm4_dtcs_warning}
            totalActiveDm4DtcsOther={total_active_dm4_dtcs_other}
            totalWarningMessagesCritical={total_warning_messages_critical}
            totalWarningMessagesWarning={total_warning_messages_warning}
            totalWarningMessagesInfo={total_warning_messages_info}
            lastMaintenanceMessageTimestamp={last_maintenance_message_timestamp}
            onClickVehicleNameAndVinAndLicensePlate={() => onClickVehicleNameAndVinAndLicensePlate(asset_id)}
            onClickCustomerName={() => onClickCustomerName(asset_id)}
            onClickVehicleInfoSection={() => onClickVehicleInfoSection(asset_id)}
            onClickMaintenanceSection={() => onClickMaintenanceSection(asset_id)}
            onClickBrakeLiningsSection={() => onClickBrakeLiningsSection(asset_id)}
            onClickWarningMessagesSection={() => onClickWarningMessagesSection(asset_id)}
            onClickDtcDm1Section={() => onClickDtcDm1Section(asset_id)}
            onClickDtcDm4Section={() => onClickDtcDm4Section(asset_id)}
            syncStatus={sync_status}
            status={status}
            highlight={highlight}
            clearHighlight={clearHighlight}
            selectedDataTabsSections={selectedDataTabsSections}
        />
    );
};
export default VehicleDataTabsComponent;
