import { useRef, useState } from 'react';
import useElementSize from '@rio-cloud/rio-uikit/lib/es/useElementSize';
import VehiclesStatusWidgetComponent from './VehiclesStatusWidgetComponent';
import useFetchVehicleStatusGlobalCounters from './useFetchVehicleStatusGlobalCounters';
import InternalErrorState from '../app/InternalErrorState';
import CustomStatsWidgetComponent from './CustomStatsWidgetComponent';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomTime } from '../../utils/DateTimeUtils';

const SCREEN_SIZE_WITH_ALL_WIDGETS = 1700;
const WIDGETS_FIRST_ROW_HEIGHT = 167;

const StatsWidgetsContainer = () => {
    const { isLoading, hasErrors, globalCounters, triggerReload, lastUpdateTimestamp } =
        useFetchVehicleStatusGlobalCounters();
    const [showAllWidgets, setShowAllWidgets] = useState(false);
    const widgetsDivRef = useRef<HTMLInputElement>(null);
    const [widgetsDivWidth, widgetsDivHeight] = useElementSize(widgetsDivRef);
    if (hasErrors) {
        return <InternalErrorState reloadTriggerFunction={triggerReload} />;
    }
    const {
        total_vehicle_status_critical,
        total_vehicle_status_warning,
        total_vehicle_status_ok,
        total_vehicle_status_unknown,
        total_maintenance_components_overdue,
        total_maintenance_components_almost_due,
        total_brake_linings_critical,
        total_brake_linings_warning,
        total_active_dm4_dtcs_critical,
        total_active_dm4_dtcs_warning,
        total_active_dm4_dtcs_other,
        total_active_dm1_dtcs_critical,
        total_active_dm1_dtcs_warning,
        total_active_dm1_dtcs_other,
    } = globalCounters;
    const isFirstLoading =
        isLoading &&
        total_vehicle_status_critical +
            total_vehicle_status_warning +
            total_vehicle_status_ok +
            total_vehicle_status_unknown ===
            0;
    const responsivePanel = 'col-xs-12 col-ls-8 col-sm-6 col-md-4 col-lg-3 col-xl-2';
    return (
        <>
            <LastUpdateLabel lastUpdateTimestamp={lastUpdateTimestamp} />
            <div
                ref={widgetsDivRef}
                className={'display-flex flex-wrap gap-20 margin-top-20'}
                data-testid={'stats-widget-container'}
                style={{
                    // This width value is needed for making sure that the position of highlight of last item is kept.
                    // Otherwise, even when vehicle status is not visible (is offscreen) the asset tree
                    // doesn't exist and the widgets wrapping affects the position.
                    width: '100vw',
                    overflowY: 'hidden',
                    maxHeight:
                        !showAllWidgets && widgetsDivWidth < SCREEN_SIZE_WITH_ALL_WIDGETS
                            ? WIDGETS_FIRST_ROW_HEIGHT
                            : undefined,
                }}
            >
                <VehiclesStatusWidgetComponent
                    responsiveGridConfig={responsivePanel}
                    isLoading={isFirstLoading}
                    totalCriticalStatus={total_vehicle_status_critical}
                    totalWarningStatus={total_vehicle_status_warning}
                    totalOkStatus={total_vehicle_status_ok}
                    totalUnknownStatus={total_vehicle_status_unknown}
                />
                <CustomStatsWidgetComponent
                    responsiveGridConfig={responsivePanel}
                    isLoading={isFirstLoading}
                    totalCriticalComponents={total_maintenance_components_overdue}
                    totalWarningComponents={total_maintenance_components_almost_due}
                    customStatsWidgetTitle={'vehicle_status.widgets.maintenance_widget.title'}
                    totalCriticalElementsLegend={'vehicle_status.maintenance.overdue_components'}
                    totalWarningElementsLegend={'vehicle_status.maintenance.almost_due_components'}
                    allGoodComponentLegend={'vehicle_status.maintenance.all_good_components'}
                />
                <CustomStatsWidgetComponent
                    responsiveGridConfig={responsivePanel}
                    isLoading={isFirstLoading}
                    totalCriticalComponents={total_brake_linings_critical}
                    totalWarningComponents={total_brake_linings_warning}
                    customStatsWidgetTitle={'vehicle_status.widgets.brake_linings_widget.title'}
                    totalCriticalElementsLegend={'vehicle_status.brake_linings.critical_levels'}
                    totalWarningElementsLegend={'vehicle_status.brake_linings.warning_levels'}
                    allGoodComponentLegend={'vehicle_status.brake_linings.all_good_levels'}
                />
                <CustomStatsWidgetComponent
                    responsiveGridConfig={responsivePanel}
                    isLoading={isFirstLoading}
                    totalCriticalComponents={total_active_dm4_dtcs_critical}
                    totalWarningComponents={total_active_dm4_dtcs_warning}
                    totalOtherComponents={total_active_dm4_dtcs_other}
                    customStatsWidgetTitle={'vehicle_status.widgets.dtcs.dm4.title'}
                    totalCriticalElementsLegend={'vehicle_status.dtcs.total_critical_dtcs'}
                    totalWarningElementsLegend={'vehicle_status.dtcs.total_warning_dtcs'}
                    totalOtherElementsLegend={'vehicle_status.dtcs.total_other_dtcs'}
                    allGoodComponentLegend={'vehicle_status.dtcs.all_good_dtcs'}
                    tooltipLegend={'vehicle_status.dtcs.dm4.tooltip.message'}
                />
                <CustomStatsWidgetComponent
                    responsiveGridConfig={responsivePanel}
                    isLoading={isFirstLoading}
                    totalCriticalComponents={total_active_dm1_dtcs_critical}
                    totalWarningComponents={total_active_dm1_dtcs_warning}
                    totalOtherComponents={total_active_dm1_dtcs_other}
                    customStatsWidgetTitle={'vehicle_status.widgets.dtcs.dm1.title'}
                    totalCriticalElementsLegend={'vehicle_status.dtcs.total_critical_dtcs'}
                    totalWarningElementsLegend={'vehicle_status.dtcs.total_warning_dtcs'}
                    totalOtherElementsLegend={'vehicle_status.dtcs.total_other_dtcs'}
                    allGoodComponentLegend={'vehicle_status.dtcs.all_good_dtcs'}
                    tooltipLegend={'vehicle_status.dtcs.dm1.tooltip.message'}
                />
            </div>
            {widgetsDivWidth < SCREEN_SIZE_WITH_ALL_WIDGETS && (
                <ShowLessOrMore isShowLess={showAllWidgets} onClick={() => setShowAllWidgets(!showAllWidgets)} />
            )}
        </>
    );
};

export type ShowLessOrMoreProps = {
    isShowLess: boolean;
    onClick: Function;
};

const LastUpdateLabel = (props: { lastUpdateTimestamp?: Date }) => {
    const { lastUpdateTimestamp } = props;
    return (
        <div className="text-color-info text-bold">
            <FormattedMessage id="vehicle_status.info_label.last_update_at" />
            <span className="padding-left-5">
                <CustomTime value={lastUpdateTimestamp} />
            </span>
            .
        </div>
    );
};

const ShowLessOrMore = (props: ShowLessOrMoreProps) => {
    const intl = useIntl();
    const { isShowLess, onClick } = props;
    return (
        <div className="cursor-pointer margin-auto padding-top-10" onClick={() => onClick()}>
            {isShowLess ? (
                <div>
                    <span>{intl.formatMessage({ id: 'vehicle_status.widgets.show_less' })}</span>
                    <span className="padding-left-10 rioglyph rioglyph-chevron-up text-size-16" />
                </div>
            ) : (
                <div>
                    <span>{intl.formatMessage({ id: 'vehicle_status.widgets.show_more' })}</span>
                    <span className="padding-left-10 rioglyph rioglyph-chevron-down text-size-16" />
                </div>
            )}
        </div>
    );
};

export default StatsWidgetsContainer;
