import { FormattedDate, FormattedTime } from 'react-intl';

type CustomDateTimeProps = {
    value?: string | number | Date;
};

// Formatting of timestamps (date and time)
export const CustomDateTime = ({ value, showWeekDay = true }: CustomDateTimeProps & { showWeekDay?: boolean }) => {
    return value !== undefined ? (
        <FormattedDate
            value={value}
            year="numeric"
            month="numeric"
            day="numeric"
            weekday={showWeekDay ? 'short' : undefined}
            hour="numeric"
            minute="numeric"
        />
    ) : null;
};

// Formatting of timestamps (time)
export const CustomTime = ({ value }: CustomDateTimeProps) => {
    return value !== undefined ? <FormattedTime value={value} hour="numeric" minute="numeric" /> : null;
};
