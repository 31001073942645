import { useIntl } from 'react-intl';
import React, { useState } from 'react';
import Button from '@rio-cloud/rio-uikit/Button';
import DataTabsSettingsDialogComponent, { VehicleStatusDataSectionKey } from './DataTabsSettingsDialogComponent';

type DataTabsSettingsDialogContainerProps = {
    savedDataTabSettings: VehicleStatusDataSectionKey[];
    onSaveDataTabSettingsDialog: Function;
};

const DataTabsSettingsDialogContainer = (props: DataTabsSettingsDialogContainerProps) => {
    const intl = useIntl();
    const [isToShowDialog, setIsToShowDialog] = useState(false);
    const { savedDataTabSettings, onSaveDataTabSettingsDialog } = props;

    return (
        <div className={'display-flex flex-column'}>
            {!isToShowDialog && (
                <Button
                    iconOnly
                    className={'btn btn-default margin-top-auto'}
                    onClick={() => {
                        setIsToShowDialog(true);
                    }}
                >
                    <span className="rioglyph rioglyph-cog" data-testid="datatabs-settings-button" />
                </Button>
            )}
            {isToShowDialog && (
                <DataTabsSettingsDialogComponent
                    title={intl.formatMessage({ id: 'vehicle_status.datatabs.settings.title' })}
                    isToShowDialog={isToShowDialog}
                    onClickCloseDialog={() => setIsToShowDialog(false)}
                    onClickSaveDialog={onSaveDataTabSettingsDialog}
                    savedDataTabSettings={savedDataTabSettings}
                />
            )}
        </div>
    );
};

export default DataTabsSettingsDialogContainer;
