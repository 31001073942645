import React from 'react';
import PieChart from '@rio-cloud/rio-uikit/lib/es/PieChart';
import { IntlShape, useIntl } from 'react-intl';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';
import { ColorMappings } from './ColorMappings';

export type VehiclesStatusWidgetComponentProps = {
    responsiveGridConfig?: string;
    isLoading: boolean;
    totalCriticalStatus: number;
    totalWarningStatus: number;
    totalOkStatus: number;
    totalUnknownStatus: number;
};

export type DataElement = {
    label: string;
    color: string;
    total?: number;
};

export type CustomPieChartComponentProps = {
    data: DataElement[];
};

const getDataStructure = (
    intl: IntlShape,
    totalCriticalStatus?: number,
    totalWarningStatus?: number,
    totalOkStatus?: number,
    totalUnknownStatus?: number,
): DataElement[] => [
    {
        label: intl.formatMessage({ id: 'vehicle_status.critical' }),
        color: ColorMappings.CRITICAL.chartColorName,
        total: totalCriticalStatus,
    },
    {
        label: intl.formatMessage({ id: 'vehicle_status.warning' }),
        color: ColorMappings.WARNING.chartColorName,
        total: totalWarningStatus,
    },
    {
        label: intl.formatMessage({ id: 'vehicle_status.ok' }),
        color: ColorMappings.OK.chartColorName,
        total: totalOkStatus,
    },
    {
        label: intl.formatMessage({ id: 'vehicle_status.unknown' }),
        color: ColorMappings.UNKNOWN.chartColorName,
        total: totalUnknownStatus,
    },
];

const SkeletonScreen = () => {
    return (
        <div
            data-testid={'vehicles-status-widget-skeleton-screen'}
            className={'display-flex flex-row justify-content-center align-items-center margin-left-20'}
        >
            <ContentLoader height={100} width={120} />
            <div className={'margin-left-25'} />
            <ContentLoader height={50} width={100} />
        </div>
    );
};

const VehiclesStatusWidgetComponent = (props: VehiclesStatusWidgetComponentProps) => {
    const intl = useIntl();
    const {
        responsiveGridConfig,
        isLoading,
        totalCriticalStatus,
        totalWarningStatus,
        totalOkStatus,
        totalUnknownStatus,
    } = props;
    const pieChartData = getDataStructure(
        intl,
        totalCriticalStatus,
        totalWarningStatus,
        totalOkStatus,
        totalUnknownStatus,
    );
    return (
        <div className={`panel panel-default panel-body display-flex flex-column ${responsiveGridConfig}`}>
            <h4 className={'text-center text-bold margin-top-0 margin-bottom-20'}>
                {intl.formatMessage({ id: 'vehicle_status.widgets.vehicles_status_widget.title' })}
            </h4>
            <div className={'display-flex justify-content-center'}>
                {isLoading ? <SkeletonScreen /> : <CustomPieChartComponent data={pieChartData} />}
            </div>
        </div>
    );
};

const CustomPieChartComponent = (props: CustomPieChartComponentProps) => {
    const { data } = props;
    return (
        <PieChart
            data={data}
            dataKey="total"
            nameKey="label"
            labels={false}
            tooltip={false}
            legend={{
                props: {
                    // https://recharts.org/en-US/api/Legend
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle',
                    content: renderCustomLegend,
                },
            }}
            innerRadius={37}
            outerRadius={45}
            height={90}
            width={240}
        />
    );
};

type ChartParamsType = {
    payload: [LegendItemParamsType];
};

type LegendItemParamsType = {
    color: string;
    value: string;
    payload: {
        total: number;
    };
};

const renderCustomLegend = (chartParams: ChartParamsType) => {
    return chartParams.payload.map((legendItemParams) => renderLegendItem(legendItemParams));
};

const renderLegendItem = (legendItemParams: LegendItemParamsType) => {
    const {
        color,
        value,
        payload: { total },
    } = legendItemParams;
    if (total === 0) {
        // hide legend if value is 0
        return null;
    }
    // Note: &#9632; is the HTML code for a filled square
    return (
        <div key={`item-${value}`} style={{ marginBottom: 6 }} data-testid="vehicle-status-widget-legend-item">
            <span className={'text-normal'}>
                <span style={{ fontSize: 14, color }}>&#9632; </span>
                <span className={'text-bold'}>{total}</span> {value}
            </span>
        </div>
    );
};

export default VehiclesStatusWidgetComponent;
