export const getUrlParamsString = (urlParams?: object) => {
    if (!urlParams) {
        return '';
    }
    // the URLSearchParams needs to be used to ensure that we get ?param=a&param=b instead of ?param[]=[a,b]
    const urlSearchParams = new URLSearchParams();
    Object.entries(urlParams).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((item) => urlSearchParams.append(key, item));
        } else if (value) {
            urlSearchParams.append(key, value);
        }
    });
    const urlParamsString = urlSearchParams.toString();
    return urlParamsString.length > 0 ? `?${urlParamsString}` : '';
};

export const isAssetTreeSelected = () => {
    const currentUrl = new URL(window.location.href);
    return currentUrl.searchParams.get('is_asset_tree_selected') === 'true';
};

export const removeUndefinedOrEmptyArrayAttributes = (object: Object) => {
    // @ts-ignore
    for (const key in object) {
        // @ts-ignore
        if (object[key] === undefined || object[key] === null) {
            // @ts-ignore
            delete object[key];
        } else {
            // @ts-ignore
            if (Array.isArray(object[key]) && object[key].length === 0) {
                // @ts-ignore
                delete object[key];
            }
        }
    }
};
