import type { PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line no-duplicate-imports
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../configuration/setup/store';

interface LocationState {
    oldLocationId: string | undefined;
    newLocationId: string;
}

const initialState: LocationState = {
    oldLocationId: undefined,
    newLocationId: 'VEHICLE_STATUS',
};

export const locationSlice = createSlice({
    name: 'locationSlice',
    initialState,
    reducers: {
        locationSliceChanged: (state, action: PayloadAction<LocationState>) => {
            const { oldLocationId, newLocationId } = action.payload;
            state.oldLocationId = oldLocationId;
            state.newLocationId = newLocationId;
        },
    },
});

// Actions
export const { locationSliceChanged } = locationSlice.actions;

// Selectors
export const getOldLocationId = (state: RootState) => state.locationSlice.oldLocationId;
export const getNewLocationId = (state: RootState) => state.locationSlice.newLocationId;

export default locationSlice.reducer;
