import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type SessionStorageStateResponse<T> = [T, Dispatch<SetStateAction<T>>];

const useSessionStorageState = <T>(initialValue: T, sessionStorageKey: string): SessionStorageStateResponse<T> => {
    const storedValue = window.sessionStorage.getItem(sessionStorageKey);
    const initial = storedValue ? JSON.parse(storedValue) : initialValue;
    const [state, setState] = useState<T>(initial);

    useEffect(() => {
        window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(state));
    }, [sessionStorageKey, state]);

    return [state, setState];
};

export default useSessionStorageState;
