import useFetchVehiclesStatusDataCsv, { VehicleStatusDataSectionKey } from './useFetchVehiclesStatusDataCsv';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Criticality, SyncStatus } from './FilterComponent';
import CsvButtonComponent from './CsvButtonComponent';

interface CsvButtonContainerProps {
    criticalityStatus: Criticality[];
    syncStatus: SyncStatus[];
    dataTabsSettings: VehicleStatusDataSectionKey[];
}

const CsvButtonContainer = (props: CsvButtonContainerProps) => {
    const intl = useIntl();
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const { criticalityStatus, syncStatus, dataTabsSettings } = props;
    const { fetchVehiclesStatusDataCsv, vehiclesStatusDataCsv, isLoading } = useFetchVehiclesStatusDataCsv({
        criticalityStatus,
        syncStatus,
        dataTabsSettings,
    });
    const handleDownloadCsv = () => {
        setIsDownloading(true);
        const file = new Blob([vehiclesStatusDataCsv!], { type: 'text/csv' });
        const fileURL = URL.createObjectURL(file);
        const tempLink = document.createElement('a');
        tempLink.href = fileURL;
        tempLink.setAttribute('download', 'Export-' + new Date().toISOString().slice(0, 10) + '.csv');
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        setIsDownloading(false);
    };

    useEffect(() => {
        if (vehiclesStatusDataCsv !== undefined) {
            handleDownloadCsv();
        }
    }, [vehiclesStatusDataCsv]);
    return (
        <div className={'display-flex flex-column'}>
            <CsvButtonComponent
                title={intl.formatMessage({ id: 'vehicle_status.csv_button.title' })}
                fetchVehiclesStatusDataCsv={fetchVehiclesStatusDataCsv}
                isProcessing={isDownloading || isLoading}
            />
        </div>
    );
};

export default CsvButtonContainer;
