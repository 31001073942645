interface CsvButtonComponentProps {
    title: string;
    fetchVehiclesStatusDataCsv: Function;
    isProcessing: boolean;
}

const CsvButtonComponent = (props: CsvButtonComponentProps) => {
    const { title, fetchVehiclesStatusDataCsv, isProcessing } = props;
    return (
        <button
            className={'btn btn-default margin-top-auto'}
            onClick={() => fetchVehiclesStatusDataCsv()}
            disabled={isProcessing}
        >
            {isProcessing && (
                <span
                    data-testid="csv-loading-spinner"
                    className="rioglyph rioglyph-spinner spinning"
                    aria-hidden="true"
                />
            )}
            <span>{title}</span>
        </button>
    );
};

export default CsvButtonComponent;
