/*
 * This file was copied and adapted from official uikit-web repository from:
 * https://bitbucket.collaboration-man.com/projects/RIOFRONT/repos/uikit-web/browse/src/components/dataTabs/DataTabs.tsx
 *
 * The idea is just to allow to temporarily have DataTabs without the content, that will only happen during the MVP.
 */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import classNames from 'classnames';

const TAB_HOVER_CLASSNAME = 'bg-highlight-decent text-color-darkest';
const NO_DATA_TABS_SELECTED = -1;

const DataTabs = (props) => {
    const { bordered, children, className: wrapperClassName, vertical, onClickFunctions, ...remainingProps } = props;

    const wrapperClassNames = classNames(
        'DataTabsContainer',
        bordered && 'DataTab-bordered',
        wrapperClassName,
        vertical && 'DataTabs-vertical',
    );

    const [hoveringIndex, setHoveringIndex] = useState(NO_DATA_TABS_SELECTED);

    return (
        <div {...remainingProps} className={wrapperClassNames}>
            <div className="DataTabsWrapper padding-bottom-0">
                <ul className="DataTabs margin-bottom-0" role="tabList">
                    {React.Children.map(children, (child, index) => {
                        // some child can be defined but not used inside DataTabs
                        // after adding validations/feature toggles
                        if (child == null) return;
                        const { tabKey, className, title } = child.props;
                        const tabClassNames = classNames(
                            'DataTab',
                            'tab',
                            hoveringIndex === index && TAB_HOVER_CLASSNAME,
                            hoveringIndex === index && 'cursor-pointer',
                            className && className,
                        );
                        return (
                            <li
                                key={tabKey}
                                className={tabClassNames}
                                onMouseEnter={() => setHoveringIndex(index)}
                                onMouseLeave={() => setHoveringIndex(NO_DATA_TABS_SELECTED)}
                                style={{ boxShadow: 'none' }}
                                onClick={() => onClickFunctions[tabKey]()}
                            >
                                {title}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

DataTabs.defaultProps = {
    className: '',
    bordered: true,
    children: [],
    vertical: false,
    onClickFunctions: [],
};
export default DataTabs;
