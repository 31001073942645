import useFetchVehiclesStatusData from './useFetchVehiclesStatusData';
import InternalErrorState from '../app/InternalErrorState';
import PaginationControlsComponent from './PaginationControlsComponent';
import FilterComponent, { Criticality, SyncStatus } from './FilterComponent';
import { IntlShape, useIntl } from 'react-intl';
import VehicleDataTabsComponent from './VehicleDataTabsComponent';
import { DetailPageTab, sendMessage, VIEW_ASSET_DETAIL } from '../app/MessageHandler';
import CsvButtonContainer from './CsvButtonContainer';
import DataTabsSettingsDialogContainer from './DataTabsSettingsDialogContainer';

const VehicleDataTabsContainer = () => {
    const {
        isLoading,
        hasErrors,
        vehiclesStatusData,
        pageSize,
        pageNumber,
        totalPages,
        isCsvExportAllowed,
        triggerReload,
        changeCriticalityStatus,
        changeSyncStatus,
        changePageNumber,
        changePageSize,
        changeSelectedDataTabSections,
        selectedDataTabsSections,
        criticalityStatus,
        syncStatus,
        setSelectedAssetId,
        highlightAssetId,
        clearHighlight,
    } = useFetchVehiclesStatusData();
    const intl = useIntl();

    const redirectToDetailPage = (assetId: string, detailPageTab: DetailPageTab = DetailPageTab.GENERAL) => {
        sendMessage({
            type: VIEW_ASSET_DETAIL,
            payload: {
                assetId,
                detailPageTab,
            },
        });
        setTimeout(() => {
            // We need to set the asset one second later to avoid
            // highlighting a moment before navigating to detail view
            setSelectedAssetId(assetId);
        }, 1000);
    };

    if (hasErrors) {
        return <InternalErrorState reloadTriggerFunction={triggerReload} additionalClassName="margin-top-20" />;
    }
    return (
        <div className={'display-flex flex-column margin-top-20'} data-testid={'vehicles-details-container'}>
            <div className={'display-flex justify-content-between'}>
                <div className={'display-flex flex-row gap-20'}>
                    <FilterComponent
                        isLoading={isLoading}
                        title={intl.formatMessage({ id: 'vehicle_status.filters.criticality.title' })}
                        placeholder={''}
                        filterOptions={getCriticalityFilterOptions(intl, criticalityStatus)}
                        onSelect={changeCriticalityStatus}
                    />
                    <FilterComponent
                        isLoading={isLoading}
                        title={intl.formatMessage({ id: 'vehicle_status.filters.sync_status.title' })}
                        placeholder={''}
                        tooltip={intl.formatMessage({ id: 'vehicle_status.filters.sync_status.tooltip.message' })}
                        filterOptions={getSyncStatusFilterOptions(intl, syncStatus)}
                        onSelect={changeSyncStatus}
                    />
                </div>
                <div className={'display-flex flex-row gap-5'}>
                    <DataTabsSettingsDialogContainer
                        savedDataTabSettings={selectedDataTabsSections}
                        onSaveDataTabSettingsDialog={changeSelectedDataTabSections}
                    />
                    {isCsvExportAllowed && (
                        <CsvButtonContainer
                            criticalityStatus={criticalityStatus}
                            syncStatus={syncStatus}
                            dataTabsSettings={selectedDataTabsSections}
                        />
                    )}
                </div>
            </div>
            <VehicleDataTabsComponent
                isLoading={isLoading}
                vehiclesStatusData={vehiclesStatusData}
                onClickVehicleNameAndVinAndLicensePlate={(asset_id: string) =>
                    redirectToDetailPage(asset_id, DetailPageTab.GENERAL)
                }
                onClickCustomerName={(asset_id: string) => redirectToDetailPage(asset_id, DetailPageTab.VEHICLE_INFO)}
                onClickVehicleInfoSection={(asset_id: string) =>
                    redirectToDetailPage(asset_id, DetailPageTab.WORKSHOP_ACTIONS)
                }
                onClickMaintenanceSection={(asset_id: string) =>
                    redirectToDetailPage(asset_id, DetailPageTab.MAINTENANCE_DAMAGE)
                }
                onClickBrakeLiningsSection={(asset_id: string) =>
                    redirectToDetailPage(asset_id, DetailPageTab.BRAKE_LININGS)
                }
                onClickWarningMessagesSection={(assetId: string) =>
                    redirectToDetailPage(assetId, DetailPageTab.WARNING_MESSAGES)
                }
                onClickDtcDm1Section={(assetId: string) => redirectToDetailPage(assetId, DetailPageTab.DTC_DM1)}
                onClickDtcDm4Section={(assetId: string) => redirectToDetailPage(assetId, DetailPageTab.DTC_DM4)}
                highlightAssetId={highlightAssetId}
                clearHighlight={clearHighlight}
                selectedDataTabsSections={selectedDataTabsSections}
            />
            {vehiclesStatusData.length > 0 && (
                <PaginationControlsComponent
                    isLoading={isLoading}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    totalPages={totalPages}
                    onPageSizeChange={changePageSize}
                    onPageNumberChange={changePageNumber}
                />
            )}
        </div>
    );
};

const getCriticalityFilterOptions = (intl: IntlShape, selectedItems: Criticality[]) => {
    return [
        {
            id: Criticality.CRITICAL,
            label: intl.formatMessage({ id: 'vehicle_status.filters.criticality.critical' }),
            icon: <span className={'rioglyph rioglyph-error-sign text-size-16 text-danger'} />,
            selected: selectedItems.length === 0 || selectedItems.includes(Criticality.CRITICAL),
        },
        {
            id: Criticality.WARNING,
            label: intl.formatMessage({ id: 'vehicle_status.filters.criticality.warning' }),
            icon: <span className={'rioglyph rioglyph-warning-sign text-size-16 text-warning'} />,
            selected: selectedItems.length === 0 || selectedItems.includes(Criticality.WARNING),
        },
        {
            id: Criticality.OK,
            label: intl.formatMessage({ id: 'vehicle_status.filters.criticality.ok' }),
            icon: <span className={'rioglyph rioglyph-ok text-size-16 text-normal'} />,
            selected: selectedItems.length === 0 || selectedItems.includes(Criticality.OK),
        },
    ];
};

const getSyncStatusFilterOptions = (intl: IntlShape, selectedItems: SyncStatus[]) => {
    return [
        {
            id: SyncStatus.SYNCED,
            label: intl.formatMessage({ id: 'vehicle_status.filters.sync_status.synced' }),
            icon: <span className={'rioglyph rioglyph-refresh text-size-16 text-success'} />,
            selected: selectedItems.length === 0 || selectedItems.includes(SyncStatus.SYNCED),
        },
        {
            id: SyncStatus.RECENTLY_SYNCED,
            label: intl.formatMessage({ id: 'vehicle_status.filters.sync_status.recently_synced' }),
            icon: <span className={'rioglyph rioglyph-refresh text-size-16 text-warning'} />,
            selected: selectedItems.length === 0 || selectedItems.includes(SyncStatus.RECENTLY_SYNCED),
        },
        {
            id: SyncStatus.NOT_SYNCED,
            label: intl.formatMessage({ id: 'vehicle_status.filters.sync_status.not_synced' }),
            icon: <span className={'rioglyph rioglyph-refresh text-size-16 text-danger'} />,
            selected: selectedItems.length === 0 || selectedItems.includes(SyncStatus.NOT_SYNCED),
        },
    ];
};

export default VehicleDataTabsContainer;
