import React from 'react';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';
import { FormattedMessage, useIntl } from 'react-intl';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';

export type CustomStatsWidgetComponentProps = {
    /** ClassName configuration in order to get a responsive panel */
    responsiveGridConfig?: string;
    /** Show loading spinner instead of the whole component */
    isLoading: boolean;
    /** Total number of elements with status Critical */
    totalCriticalComponents: number;
    /** Total number of elements with status Warning */
    totalWarningComponents: number;
    /** Total number of elements with status Other*/
    totalOtherComponents?: number;
    /** Title of the Widget */
    customStatsWidgetTitle: string;
    /** Legend of total elements with status Critical */
    totalCriticalElementsLegend: string;
    /** Legend of total elements with status Warning */
    totalWarningElementsLegend: string;
    /** Legend of total elements with status Other */
    totalOtherElementsLegend?: string;
    /** Legend when there is no warning/critical elements */
    allGoodComponentLegend: string;
    /** Tooltip message **/
    tooltipLegend?: string;
};

const StatsSkeletonScreen = () => {
    return (
        <div
            data-testid={'custom-stats-widget-skeleton-screen'}
            className={'display-flex flex-column justify-content-center align-items-center margin-top-20'}
        >
            <ContentLoader height={40} width={50} />
            <div className={'margin-top-10'} />
            <ContentLoader height={50} width={150} />
        </div>
    );
};

const CustomStatsWidgetComponent = (props: CustomStatsWidgetComponentProps) => {
    const intl = useIntl();
    const {
        responsiveGridConfig,
        isLoading,
        totalCriticalComponents,
        totalWarningComponents,
        customStatsWidgetTitle,
        allGoodComponentLegend,
        totalOtherComponents,
        tooltipLegend,
    } = props;
    const totalComponents =
        totalCriticalComponents +
        totalWarningComponents +
        (totalOtherComponents !== undefined ? totalOtherComponents : 0);
    return (
        <div className={`panel panel-default panel-body display-flex flex-column ${responsiveGridConfig}`}>
            <h4 className={'text-center text-bold margin-top-0 margin-bottom-0'}>
                {intl.formatMessage({ id: customStatsWidgetTitle })}
                {tooltipLegend && (
                    <OverlayTrigger
                        placement={Tooltip.RIGHT}
                        overlay={
                            <Tooltip allowOnTouch textAlignment="left" width={200}>
                                <FormattedMessage id={tooltipLegend} />
                            </Tooltip>
                        }
                    >
                        <span
                            data-testid="tooltipInfoIcon"
                            className="rioglyph rioglyph-info-sign text-size-16 margin-left-10"
                            style={{ marginTop: 2 }}
                        />
                    </OverlayTrigger>
                )}
            </h4>
            <div className={'display-flex justify-content-center'}>
                {isLoading ? (
                    <StatsSkeletonScreen />
                ) : (
                    <div>
                        <div className={'text-center'}>
                            <h3>{totalComponents}</h3>
                        </div>
                        <div className="margin-top-10">
                            {totalComponents > 0 ? (
                                <CustomStatsWidget {...props} />
                            ) : (
                                <div className="margin-top-20 text-center">
                                    <span>{intl.formatMessage({ id: allGoodComponentLegend })}</span>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const CustomStatsWidget = (props: CustomStatsWidgetComponentProps) => {
    const intl = useIntl();
    const {
        totalCriticalComponents,
        totalCriticalElementsLegend,
        totalWarningComponents,
        totalWarningElementsLegend,
        totalOtherComponents,
        totalOtherElementsLegend,
    } = props;
    return (
        <>
            {totalCriticalComponents > 0 && (
                <div className="margin-top-10 text-center" data-testid="custom-stats-widget-levels-item">
                    <span className="glyphicon rioglyph-error-sign text-danger text-size-16" />
                    <span className="text-bold margin-left-5">{totalCriticalComponents} </span>
                    <span>{intl.formatMessage({ id: totalCriticalElementsLegend })}</span>
                </div>
            )}
            {totalWarningComponents > 0 && (
                <div className="margin-top-10 text-center" data-testid="custom-stats-widget-levels-item">
                    <span className="glyphicon rioglyph-warning-sign text-warning text-size-16" />
                    <span className="text-bold margin-left-5">{totalWarningComponents} </span>
                    <span>{intl.formatMessage({ id: totalWarningElementsLegend })}</span>
                </div>
            )}
            {totalOtherComponents !== undefined && totalOtherComponents > 0 && (
                <div className="margin-top-10 text-center" data-testid="custom-stats-widget-levels-item">
                    <span className="glyphicon rioglyph rioglyph-ongoing text-color-gray text-size-16" />
                    <span className="text-bold margin-left-5">{totalOtherComponents} </span>
                    <span>{intl.formatMessage({ id: totalOtherElementsLegend })}</span>
                </div>
            )}
        </>
    );
};

export default CustomStatsWidgetComponent;
