import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useRef, useState } from 'react';
import DataTabs from './DataTabs'; // TODO: replace by original uikit component (after MVP)
import DataTab from '@rio-cloud/rio-uikit/lib/es/DataTab';
import NextAppointmentTooltipComponent from './NextAppointmentTooltipComponent';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { CustomDateTime } from '../../utils/DateTimeUtils';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import { ColorMappings } from './ColorMappings';
import { VehicleStatusDataSectionKey, VehicleStatusDataSections } from './DataTabsSettingsDialogComponent';

const HIGHLIGHT_DURATION_SECONDS = 2;

export type VehicleDataTabComponentProps = {
    type?: string;
    name?: string;
    vin?: string;
    customerName?: string;
    licensePlate?: string;
    deviceType?: string;
    totalAppointments?: number;
    totalWorkshopActions?: number;
    totalVehicleNotes?: number;
    nextWorkshopAppointment?: string;
    totalComponentsOverdue?: number;
    totalComponentsAlmostDue?: number;
    totalBrakeLiningsCritical?: number;
    totalBrakeLiningsWarning?: number;
    totalActiveDm1DtcsCritical?: number;
    totalActiveDm1DtcsWarning?: number;
    totalActiveDm1DtcsOther?: number;
    totalActiveDm4DtcsCritical?: number;
    totalActiveDm4DtcsWarning?: number;
    totalActiveDm4DtcsOther?: number;
    totalWarningMessagesCritical?: number;
    totalWarningMessagesWarning?: number;
    totalWarningMessagesInfo?: number;
    lastMaintenanceMessageTimestamp?: string;
    onClickVehicleInfoSection: Function;
    onClickMaintenanceSection: Function;
    onClickBrakeLiningsSection: Function;
    onClickWarningMessagesSection: Function;
    onClickDtcDm1Section: Function;
    onClickDtcDm4Section: Function;
    onClickVehicleNameAndVinAndLicensePlate: Function;
    onClickCustomerName: Function;
    syncStatus: string;
    status: string;
    highlight: boolean;
    clearHighlight: Function;
    selectedDataTabsSections: VehicleStatusDataSectionKey[];
};

export type VehicleBasicDataComponentProps = {
    type?: string;
    name?: string;
    vin?: string;
    customerName?: string;
    licensePlate?: string;
    lastMaintenanceMessageTimestamp?: string;
    syncStatus: string;
    onClickVehicleNameAndVinAndLicensePlate: Function;
    onClickCustomerName: Function;
    highlight: boolean;
};

export type VehicleDataComponentProps = {
    deviceType?: string;
    totalAppointments?: number;
    totalWorkshopActions?: number;
    totalVehicleNotes?: number;
    nextWorkshopAppointment?: string;
    totalComponentsOverdue?: number;
    totalComponentsAlmostDue?: number;
    totalBrakeLiningsCritical?: number;
    totalBrakeLiningsWarning?: number;
    totalActiveDm1DtcsCritical?: number;
    totalActiveDm1DtcsWarning?: number;
    totalActiveDm1DtcsOther?: number;
    totalActiveDm4DtcsCritical?: number;
    totalActiveDm4DtcsWarning?: number;
    totalActiveDm4DtcsOther?: number;
    totalWarningMessagesCritical?: number;
    totalWarningMessagesWarning?: number;
    totalWarningMessagesInfo?: number;
    onClickVehicleInfoSection: Function;
    onClickMaintenanceSection: Function;
    onClickBrakeLiningsSection: Function;
    onClickWarningMessagesSection: Function;
    onClickDtcDm1Section: Function;
    onClickDtcDm4Section: Function;
    highlight: boolean;
    selectedDataTabsSections: VehicleStatusDataSectionKey[];
};

export type dataTabSectionDetailsComponentProps = {
    dataTabSectionDetailsTitle: string;
    totalCriticalItems?: number;
    totalWarningItems?: number;
    totalOtherItems?: number;
};

const getIconByVehicleType = (type: string | undefined) => {
    if (type?.toUpperCase() === 'TRUCK' || type?.toUpperCase() === 'BUS' || type?.toUpperCase() === 'VAN') {
        return `rioglyph rioglyph-${type.toLowerCase()}`;
    }
};

const getSyncStatusColor = (syncStatus: string) => {
    switch (syncStatus) {
        case 'NOT_SYNCED':
            return {
                syncStatusColor: 'danger',
                syncStatusMessage: 'vehicle_status.datatabs.vehicle_info.sync_status.not_synced',
            };
        case 'RECENTLY_SYNCED':
            return {
                syncStatusColor: 'warning',
                syncStatusMessage: 'vehicle_status.datatabs.vehicle_info.sync_status.recently_synced',
            };
        case 'SYNCED':
            return {
                syncStatusColor: 'success',
                syncStatusMessage: 'vehicle_status.datatabs.vehicle_info.sync_status.synced',
            };
    }
};

const syncStatusIcon = (lastMaintenanceMessageTimestamp: string | undefined, syncStatus: string) => {
    const lastUpdateStatusMessage = 'vehicle_status.datatabs.vehicle_info.sync_status.maintenance_last_update';
    const { syncStatusColor, syncStatusMessage } = getSyncStatusColor(syncStatus)!;
    return (
        <OverlayTrigger
            placement={Tooltip.RIGHT}
            overlay={
                <Tooltip allowOnTouch textAlignment="left" width={200}>
                    <span className="text-bold">
                        <FormattedMessage id={syncStatusMessage} />
                    </span>
                    {lastMaintenanceMessageTimestamp && (
                        <>
                            <br />
                            <FormattedMessage id={lastUpdateStatusMessage} />
                            <span className="padding-left-5">
                                <CustomDateTime value={lastMaintenanceMessageTimestamp} showWeekDay={false} />
                            </span>
                        </>
                    )}
                </Tooltip>
            }
        >
            <span className={`rioglyph rioglyph-refresh text-size-16 text-${syncStatusColor}`} />
        </OverlayTrigger>
    );
};

const getStatusColorClassName = (status: string) => {
    switch (status) {
        case 'CRITICAL':
            return ColorMappings.CRITICAL.backgroundColorClassName;
        case 'WARNING':
            return ColorMappings.WARNING.backgroundColorClassName;
        case 'OK':
            return ColorMappings.OK.backgroundColorClassName;
        default:
            return ColorMappings.UNKNOWN.backgroundColorClassName;
    }
};

const VehicleBasicDataComponent = (props: VehicleBasicDataComponentProps) => {
    const intl = useIntl();
    const {
        type,
        name,
        vin,
        customerName,
        licensePlate,
        lastMaintenanceMessageTimestamp,
        syncStatus,
        onClickVehicleNameAndVinAndLicensePlate,
        onClickCustomerName,
        highlight,
    } = props;
    return (
        <>
            <div
                className={
                    'display-flex align-content-start gap-20 padding-10' +
                    ' flex-1-0 margin-top-20 border border-bottom-none' +
                    (highlight ? ' bg-light border-color-dark' : ' bg-lighter')
                }
                data-testid="vehicle-basic-data"
            >
                <div data-testid="vehicle-basic-data-type-icon">
                    <span className={`${getIconByVehicleType(type)} text-size-16`} />
                </div>
                <div>{syncStatusIcon(lastMaintenanceMessageTimestamp, syncStatus)}</div>
                <div data-testid="vehicle-basic-data-name">
                    <span className="margin-right-10 text-color-highlight-light">
                        {intl.formatMessage({ id: 'vehicle_status.datatabs.vehicle_info.name' })}
                    </span>
                    <a className="hover-text-decoration-none" onClick={() => onClickVehicleNameAndVinAndLicensePlate()}>
                        {name}
                    </a>
                </div>
                <div data-testid="vehicle-basic-data-vin">
                    <span className="margin-right-10 text-color-highlight-light">
                        {intl.formatMessage({ id: 'vehicle_status.datatabs.vehicle_info.vin' })}
                    </span>
                    <a className="hover-text-decoration-none" onClick={() => onClickVehicleNameAndVinAndLicensePlate()}>
                        {vin}
                    </a>
                </div>
                <div data-testid="vehicle-basic-data-customer_name">
                    <span className="margin-right-10 text-color-highlight-light">
                        {intl.formatMessage({ id: 'vehicle_status.datatabs.vehicle_info.customer_name' })}
                    </span>
                    <a className="hover-text-decoration-none" onClick={() => onClickCustomerName()}>
                        {customerName}
                    </a>
                </div>
                <div data-testid="vehicle-basic-data-license-plate">
                    <span className="margin-right-10 text-color-highlight-light">
                        {intl.formatMessage({ id: 'vehicle_status.datatabs.vehicle_info.license_plate' })}
                    </span>
                    <a className="hover-text-decoration-none" onClick={() => onClickVehicleNameAndVinAndLicensePlate()}>
                        {licensePlate ||
                            intl.formatMessage({ id: 'vehicle_status.datatabs.vehicle_info.license_plate.not_added' })}
                    </a>
                </div>
            </div>
        </>
    );
};

const VehicleDataComponent = (props: VehicleDataComponentProps) => {
    const intl = useIntl();
    const {
        deviceType,
        totalAppointments,
        totalWorkshopActions,
        totalVehicleNotes,
        nextWorkshopAppointment,
        totalComponentsOverdue,
        totalComponentsAlmostDue,
        totalBrakeLiningsCritical,
        totalBrakeLiningsWarning,
        totalActiveDm1DtcsCritical,
        totalActiveDm1DtcsWarning,
        totalActiveDm1DtcsOther,
        totalActiveDm4DtcsCritical,
        totalActiveDm4DtcsWarning,
        totalActiveDm4DtcsOther,
        totalWarningMessagesCritical,
        totalWarningMessagesWarning,
        totalWarningMessagesInfo,
        onClickVehicleInfoSection,
        onClickMaintenanceSection,
        onClickBrakeLiningsSection,
        onClickWarningMessagesSection,
        onClickDtcDm1Section,
        onClickDtcDm4Section,
        highlight,
        selectedDataTabsSections,
    } = props;
    const onClickFunctions = {
        'workshop-details-tab': onClickVehicleInfoSection,
        'maintenance-details-tab': onClickMaintenanceSection,
        'brake-linings-details-tab': onClickBrakeLiningsSection,
        'warning-messages-details-tab': onClickWarningMessagesSection,
        'dtc-dm4-details-tab': onClickDtcDm4Section,
        'dtc-dm1-details-tab': onClickDtcDm1Section,
    };
    return (
        <DataTabs onClickFunctions={onClickFunctions} className={highlight ? 'bg-light border-color-dark' : ''}>
            <DataTab
                tabKey="workshop-details-tab"
                title={
                    <>
                        <span>
                            {intl.formatMessage({ id: 'vehicle_status.datatabs.workshop_details.vehicle_info' })}
                            <NextAppointmentTooltipComponent nextWorkshopAppointment={nextWorkshopAppointment} />
                        </span>
                        <div className={'display-flex align-content-start padding-top-10 gap-10 flex-1-0'}>
                            <div>
                                <span className={'margin-right-5 rioglyph rioglyph-calendar text-size-16'} />
                                <span>{totalAppointments}</span>
                            </div>
                            <div>
                                <span className={'margin-right-5 rioglyph rioglyph-workshop text-size-16'} />
                                <span>{totalWorkshopActions}</span>
                            </div>
                            <div>
                                <span className={'margin-right-5 rioglyph rioglyph-comment text-size-16'} />
                                <span>{totalVehicleNotes}</span>
                            </div>
                        </div>
                    </>
                }
            />
            {selectedDataTabsSections.includes(VehicleStatusDataSections.MAINTENANCE) && (
                <DataTab
                    tabKey="maintenance-details-tab"
                    className={'border border-left-only'}
                    title={
                        <DataTabSectionDetails
                            dataTabSectionDetailsTitle={'vehicle_status.datatabs.maintenance_details.maintenance'}
                            totalCriticalItems={totalComponentsOverdue}
                            totalWarningItems={totalComponentsAlmostDue}
                        />
                    }
                />
            )}
            {selectedDataTabsSections.includes(VehicleStatusDataSections.BRAKE_LININGS) && (
                <DataTab
                    tabKey="brake-linings-details-tab"
                    className={'border border-left-only'}
                    title={
                        <DataTabSectionDetails
                            dataTabSectionDetailsTitle={'vehicle_status.datatabs.brake_linings_details.brake_linings'}
                            totalCriticalItems={totalBrakeLiningsCritical}
                            totalWarningItems={totalBrakeLiningsWarning}
                        />
                    }
                />
            )}
            {deviceType === 'ocu3' && selectedDataTabsSections.includes(VehicleStatusDataSections.WARNING_MESSAGES) && (
                <DataTab
                    tabKey="warning-messages-details-tab"
                    className={'border border-left-only'}
                    title={
                        <DataTabSectionDetails
                            dataTabSectionDetailsTitle={
                                'vehicle_status.datatabs.warning_messages_details.warning_messages'
                            }
                            totalCriticalItems={totalWarningMessagesCritical}
                            totalWarningItems={totalWarningMessagesWarning}
                            totalOtherItems={totalWarningMessagesInfo}
                        />
                    }
                />
            )}
            {(deviceType === 'tbm3' || deviceType === 'cm4') &&
                selectedDataTabsSections.includes(VehicleStatusDataSections.DTC_DIAGNOSTIC_MEMORY) && (
                    <DataTab
                        tabKey="dtc-dm4-details-tab"
                        className={'border border-left-only'}
                        title={
                            <DataTabSectionDetails
                                dataTabSectionDetailsTitle={'vehicle_status.datatabs.dtc_diagnostic_memory'}
                                totalCriticalItems={totalActiveDm4DtcsCritical}
                                totalWarningItems={totalActiveDm4DtcsWarning}
                                totalOtherItems={totalActiveDm4DtcsOther}
                            />
                        }
                    />
                )}
            {(deviceType === 'tbm3' || deviceType === 'cm4') &&
                selectedDataTabsSections.includes(VehicleStatusDataSections.DTC_ACTIVE_ERRORS) && (
                    <DataTab
                        tabKey="dtc-dm1-details-tab"
                        className={'border border-left-only'}
                        title={
                            <DataTabSectionDetails
                                dataTabSectionDetailsTitle={'vehicle_status.datatabs.dtc_active_errors'}
                                totalCriticalItems={totalActiveDm1DtcsCritical}
                                totalWarningItems={totalActiveDm1DtcsWarning}
                                totalOtherItems={totalActiveDm1DtcsOther}
                            />
                        }
                    />
                )}
        </DataTabs>
    );
};

const VehicleDataTabComponent = (props: VehicleDataTabComponentProps) => {
    const {
        type,
        name,
        vin,
        customerName,
        licensePlate,
        deviceType,
        totalAppointments,
        totalWorkshopActions,
        totalVehicleNotes,
        nextWorkshopAppointment,
        totalComponentsOverdue,
        totalComponentsAlmostDue,
        totalBrakeLiningsCritical,
        totalBrakeLiningsWarning,
        totalActiveDm1DtcsCritical,
        totalActiveDm1DtcsWarning,
        totalActiveDm1DtcsOther,
        totalActiveDm4DtcsCritical,
        totalActiveDm4DtcsWarning,
        totalActiveDm4DtcsOther,
        totalWarningMessagesCritical,
        totalWarningMessagesWarning,
        totalWarningMessagesInfo,
        lastMaintenanceMessageTimestamp,
        onClickVehicleNameAndVinAndLicensePlate,
        onClickCustomerName,
        onClickVehicleInfoSection,
        onClickMaintenanceSection,
        onClickBrakeLiningsSection,
        onClickWarningMessagesSection,
        onClickDtcDm1Section,
        onClickDtcDm4Section,
        syncStatus,
        status,
        highlight,
        clearHighlight,
        selectedDataTabsSections,
    } = props;

    useEffect(() => {
        if (highlight) {
            setTimeout(() => clearHighlight(), HIGHLIGHT_DURATION_SECONDS * 1000);
        }
    }, [highlight]);

    return (
        <div className={'display-flex flex-row'}>
            <div
                className={`display-flex margin-top-20 ${getStatusColorClassName(status)}`}
                style={{
                    minWidth: 4,
                }}
            />
            <div className={'display-flex flex-column flex-1-0'}>
                <VehicleBasicDataComponent
                    type={type}
                    name={name}
                    vin={vin}
                    customerName={customerName}
                    licensePlate={licensePlate}
                    lastMaintenanceMessageTimestamp={lastMaintenanceMessageTimestamp}
                    syncStatus={syncStatus}
                    onClickVehicleNameAndVinAndLicensePlate={onClickVehicleNameAndVinAndLicensePlate}
                    onClickCustomerName={onClickCustomerName}
                    highlight={highlight}
                />
                <VehicleDataComponent
                    deviceType={deviceType}
                    totalAppointments={totalAppointments}
                    totalWorkshopActions={totalWorkshopActions}
                    totalVehicleNotes={totalVehicleNotes}
                    nextWorkshopAppointment={nextWorkshopAppointment}
                    totalComponentsOverdue={totalComponentsOverdue}
                    totalComponentsAlmostDue={totalComponentsAlmostDue}
                    totalBrakeLiningsCritical={totalBrakeLiningsCritical}
                    totalBrakeLiningsWarning={totalBrakeLiningsWarning}
                    totalActiveDm1DtcsCritical={totalActiveDm1DtcsCritical}
                    totalActiveDm1DtcsWarning={totalActiveDm1DtcsWarning}
                    totalActiveDm1DtcsOther={totalActiveDm1DtcsOther}
                    totalActiveDm4DtcsCritical={totalActiveDm4DtcsCritical}
                    totalActiveDm4DtcsWarning={totalActiveDm4DtcsWarning}
                    totalActiveDm4DtcsOther={totalActiveDm4DtcsOther}
                    totalWarningMessagesCritical={totalWarningMessagesCritical}
                    totalWarningMessagesWarning={totalWarningMessagesWarning}
                    totalWarningMessagesInfo={totalWarningMessagesInfo}
                    onClickVehicleInfoSection={onClickVehicleInfoSection}
                    onClickMaintenanceSection={onClickMaintenanceSection}
                    onClickBrakeLiningsSection={onClickBrakeLiningsSection}
                    onClickWarningMessagesSection={onClickWarningMessagesSection}
                    onClickDtcDm1Section={onClickDtcDm1Section}
                    onClickDtcDm4Section={onClickDtcDm4Section}
                    highlight={highlight}
                    selectedDataTabsSections={selectedDataTabsSections}
                />
            </div>
        </div>
    );
};

const countItems = (totalItems?: number) => (totalItems === undefined ? 0 : totalItems);

const DataTabSectionDetails = (props: dataTabSectionDetailsComponentProps) => {
    const intl = useIntl();
    const { dataTabSectionDetailsTitle, totalCriticalItems, totalWarningItems, totalOtherItems } = props;
    const noDataReceived =
        totalCriticalItems === undefined && totalWarningItems === undefined && totalOtherItems === undefined;
    const areAllItemsOk =
        countItems(totalCriticalItems) + countItems(totalWarningItems) + countItems(totalOtherItems) === 0;
    return (
        <>
            <span>{intl.formatMessage({ id: dataTabSectionDetailsTitle })}</span>
            <div className={'display-flex align-content-start padding-top-10 gap-10 flex-1-0'}>
                {noDataReceived ? (
                    <span className={'text-color-highlight-light text-size-12'}>
                        {intl.formatMessage({ id: 'vehicle_status.datatabs.vehicle_data.no_data' })}
                    </span>
                ) : (
                    <>
                        {areAllItemsOk && (
                            <span
                                className={'margin-right-5 glyphicon rioglyph rioglyph-ok text-size-16'}
                                data-testid={'details-for-data-tabs-icon'}
                            />
                        )}
                        {totalCriticalItems !== undefined && totalCriticalItems > 0 && (
                            <div>
                                <span
                                    className={'margin-right-5 glyphicon rioglyph-error-sign text-danger text-size-16'}
                                    data-testid={'details-for-data-tabs-icon'}
                                />
                                <span>{totalCriticalItems}</span>
                            </div>
                        )}
                        {totalWarningItems !== undefined && totalWarningItems > 0 && (
                            <div>
                                <span
                                    className={
                                        'margin-right-5 glyphicon rioglyph-warning-sign text-warning text-size-16'
                                    }
                                    data-testid={'details-for-data-tabs-icon'}
                                />
                                <span>{totalWarningItems}</span>
                            </div>
                        )}
                        {totalOtherItems !== undefined && totalOtherItems > 0 && (
                            <div>
                                <span
                                    className={'margin-right-5 glyphicon rioglyph-ongoing text-color-gray text-size-16'}
                                    data-testid={'details-for-data-tabs-icon'}
                                />
                                <span>{totalOtherItems}</span>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default VehicleDataTabComponent;
