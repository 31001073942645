import { Suspense, useEffect } from 'react';
import { LoadingIndicator } from './LoadingIndicator';
import StatsWidgetsContainer from '../vehicle-status/StatsWidgetsContainer';
import VehicleDataTabsContainer from '../vehicle-status/VehicleDataTabsContainer';
import { REQUEST_ASSET_TREE_SELECTION, sendMessage } from './MessageHandler';
import { isAssetTreeSelected } from '../../utils/commonUtils';

const requestAssetTreeSelection = () => {
    sendMessage({
        type: REQUEST_ASSET_TREE_SELECTION,
        payload: {},
    });
};

const MainComponent = () => {
    useEffect(() => {
        if (isAssetTreeSelected()) {
            requestAssetTreeSelection();
        }
    }, []);
    return (
        <div className={'overflow-hidden display-flex flex-column padding-20'} style={{ backgroundColor: '#FFFFFF' }}>
            <Suspense fallback={<LoadingIndicator />}>
                <StatsWidgetsContainer />
                <VehicleDataTabsContainer />
            </Suspense>
        </div>
    );
};

export default MainComponent;
