import Dialog from '@rio-cloud/rio-uikit/Dialog';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { FormattedMessage, useIntl } from 'react-intl';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import { useState } from 'react';
import { gaPush } from '../../configuration/setup/googleAnalytics';

type DataTabsSettingsDialogComponentProps = {
    title: string;
    isToShowDialog: boolean;
    onClickCloseDialog: Function;
    onClickSaveDialog: Function;
    savedDataTabSettings: VehicleStatusDataSectionKey[];
};

const DataTabsSettingsDialogComponent = (props: DataTabsSettingsDialogComponentProps) => {
    const intl = useIntl();
    const { title, isToShowDialog, onClickCloseDialog, onClickSaveDialog, savedDataTabSettings } = props;
    const [selectedSections, setSelectedSections] = useState<VehicleStatusDataSectionKey[]>(savedDataTabSettings);
    const savingDataTabsSettings = () => {
        onClickSaveDialog(selectedSections);
        onClickCloseDialog();
        const selectedColumns = `selected_columns: ${selectedSections
            .map(
                (section) =>
                    ShortenedVehicleStatusDataSections[section as keyof typeof ShortenedVehicleStatusDataSections],
            )
            .join(',')}`;
        gaPush({
            event: 'vs_selected_columns_in_settings',
            parameters: {
                element_name: 'VehicleStatus_DataTabsSettingsDialogComponent',
                component_name: 'Dialog',
                trigger: 'click',
                element_state: selectedColumns,
            },
            userProps: {},
        });
    };

    return (
        <div data-testid="datatabs-settings-dialog">
            <ConfirmationDialog
                show={isToShowDialog}
                title={title}
                content={renderDialogContent(selectedSections, setSelectedSections)}
                bsSize={Dialog.SIZE_MD}
                onClickConfirm={savingDataTabsSettings}
                onClickCancel={onClickCloseDialog}
                cancelButtonText={intl.formatMessage({ id: 'vehicle_status.datatabs.settings.button.cancel' })}
                confirmButtonText={intl.formatMessage({ id: 'vehicle_status.datatabs.settings.button.save' })}
                useOverflow
            />
        </div>
    );
};

const renderDialogContent = (savedDataTabSettings: VehicleStatusDataSectionKey[], setSelectedSections: Function) => {
    return (
        <div>
            <ul className="nav nav-tabs nav-createDialog">
                <li className={'nav-list-element active'}>
                    <a href="#" onClick={() => {}}>
                        <FormattedMessage id="vehicle_status.datatabs.settings.table" />
                    </a>
                </li>
            </ul>
            <br />
            <h5 className={'text-left text-bold margin-top-0 margin-bottom-20'}>
                <FormattedMessage id="vehicle_status.datatabs.settings.table.title" />
            </h5>
            <br />
            {renderDataTabsSections(savedDataTabSettings, setSelectedSections)}
        </div>
    );
};

const renderDataTabsSections = (selectedSections: VehicleStatusDataSectionKey[], setSelectedSections: Function) => {
    const intl = useIntl();
    const sectionsSettings = [
        {
            title: 'vehicle_status.datatabs.settings.title.maintenance',
            dataSection: VehicleStatusDataSections.MAINTENANCE,
        },
        {
            title: 'vehicle_status.datatabs.settings.title.brake_linings',
            dataSection: VehicleStatusDataSections.BRAKE_LININGS,
        },
        {
            title: 'vehicle_status.datatabs.settings.title.dtc_diagnostic_memory',
            dataSection: VehicleStatusDataSections.DTC_DIAGNOSTIC_MEMORY,
        },
        {
            title: 'vehicle_status.datatabs.settings.title.dtc_active_errors',
            dataSection: VehicleStatusDataSections.DTC_ACTIVE_ERRORS,
        },
        {
            title: 'vehicle_status.datatabs.settings.title.warning_messages',
            dataSection: VehicleStatusDataSections.WARNING_MESSAGES,
        },
    ];

    const handleCheckboxChange = (section: VehicleStatusDataSectionKey) => {
        // Ensure at least one checkbox is always selected
        const selectedSectionsIgnoringVehicleInfo = selectedSections.filter(
            (selected) => selected !== VehicleStatusDataSections.VEHICLE_INFO,
        );
        if (selectedSectionsIgnoringVehicleInfo.length === 1 && selectedSections.includes(section)) {
            return;
        }

        const updatedSelection = selectedSections.includes(section)
            ? selectedSections.filter((selected) => selected !== section)
            : [...selectedSections, section];

        setSelectedSections(updatedSelection);
    };

    const dataSectionConfiguration = sectionsSettings.map((section, id) => (
        <div key={id} className="checkboxContainer" data-component={id}>
            <Checkbox
                onClick={() => handleCheckboxChange(section.dataSection)}
                checked={selectedSections.includes(section.dataSection)}
            >
                <FormattedMessage id={section.title} />
            </Checkbox>
        </div>
    ));

    return (
        <div data-testid="datatabs-settings-columns">
            <ExpanderPanel
                title={intl.formatMessage({ id: 'vehicle_status.datatabs.settings.columns' })}
                bsStyle="default"
                open
            >
                {dataSectionConfiguration}
            </ExpanderPanel>
        </div>
    );
};

export const VehicleStatusDataSections = {
    VEHICLE_INFO: 'VEHICLE_INFO',
    MAINTENANCE: 'MAINTENANCE',
    BRAKE_LININGS: 'BRAKE_LININGS',
    DTC_DIAGNOSTIC_MEMORY: 'DTC_DIAGNOSTIC_MEMORY',
    DTC_ACTIVE_ERRORS: 'DTC_ACTIVE_ERRORS',
    WARNING_MESSAGES: 'WARNING_MESSAGES',
} as const;

// It needs to be shortened because Google Analytics has a maximum limit of 100 characters per parameter
export const ShortenedVehicleStatusDataSections = {
    VEHICLE_INFO: 'VEH_INFO',
    MAINTENANCE: 'MAINT',
    BRAKE_LININGS: 'BRK_LIN',
    DTC_DIAGNOSTIC_MEMORY: 'DTC_DM4',
    DTC_ACTIVE_ERRORS: 'DTC_DM1',
    WARNING_MESSAGES: 'WRN_MSG',
} as const;

export type VehicleStatusDataSectionKey = (typeof VehicleStatusDataSections)[keyof typeof VehicleStatusDataSections];
export default DataTabsSettingsDialogComponent;
