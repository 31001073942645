import type { PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line no-duplicate-imports
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../configuration/setup/store';

interface AssetTreeState {
    assetIds: string[];
    accountIds: string[];
    workshopIds: string[];
}

const initialState: AssetTreeState = {
    assetIds: [],
    accountIds: [],
    workshopIds: [],
};

export const assetTreeSlice = createSlice({
    name: 'assetTree',
    initialState,
    reducers: {
        assetTreeSelectionChanged: (state, action: PayloadAction<AssetTreeState>) => {
            const { assetIds, accountIds, workshopIds } = action.payload;
            state.assetIds = assetIds;
            state.accountIds = accountIds;
            state.workshopIds = workshopIds;
        },
    },
});

// Actions
export const { assetTreeSelectionChanged } = assetTreeSlice.actions;

// Selectors
export const getAssetTreeAssetIds = (state: RootState) => state.assetTree.assetIds;
export const getAssetTreeAccountIds = (state: RootState) => state.assetTree.accountIds;
export const getAssetTreeWorkshopIds = (state: RootState) => state.assetTree.workshopIds;

export default assetTreeSlice.reducer;
