import axios from 'axios';
import { useEffect, useState } from 'react';
import { config } from '../../config';
import { GetVehicleStatusGlobalCounters, VehicleStatusGlobalCounters } from '../../generated/GeneratedApiTypes';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getAssetTreeAccountIds, getAssetTreeAssetIds, getAssetTreeWorkshopIds } from '../app/assetTreeSlice';
import { isAssetTreeSelected, removeUndefinedOrEmptyArrayAttributes } from '../../utils/commonUtils';
import { getNewLocationId, getOldLocationId } from '../app/locationSlice';
import { sendMessage, SHOW_REFRESH_PAGE_NOTIFICATION } from '../app/MessageHandler';

type RequestBody = GetVehicleStatusGlobalCounters.GetVehicleStatusGlobalCounters.RequestBody;
type ResponseBody = GetVehicleStatusGlobalCounters.GetVehicleStatusGlobalCounters.ResponseBody;

const useFetchVehicleStatusGlobalCounters = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [ignoreFetch, setIgnoreFetch] = useState<boolean>(isAssetTreeSelected());
    const [reloadState, setReloadState] = useState<{}>({});
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<Date | undefined>(undefined);
    const [globalCounters, setGlobalCounters] = useState<VehicleStatusGlobalCounters>({
        total_brake_linings_critical: 0,
        total_brake_linings_warning: 0,
        total_maintenance_components_almost_due: 0,
        total_maintenance_components_overdue: 0,
        total_vehicle_status_critical: 0,
        total_vehicle_status_warning: 0,
        total_vehicle_status_ok: 0,
        total_vehicle_status_unknown: 0,
        total_active_dm1_dtcs_critical: 0,
        total_active_dm1_dtcs_warning: 0,
        total_active_dm1_dtcs_other: 0,
        total_active_dm4_dtcs_critical: 0,
        total_active_dm4_dtcs_warning: 0,
        total_active_dm4_dtcs_other: 0,
    });
    // get values from asset tree
    const assetIds = useAppSelector(getAssetTreeAssetIds);
    const accountIds = useAppSelector(getAssetTreeAccountIds);
    const workshopIds = useAppSelector(getAssetTreeWorkshopIds);

    const oldLocationId = useAppSelector(getOldLocationId);
    const newLocationId = useAppSelector(getNewLocationId);

    const triggerReload = () => {
        setIsLoading(true);
        setHasErrors(false);
        setReloadState({});
    };

    const url = `${config.backend.BACKEND_URI}/get-vehicle-status-global-counters`;
    const body: RequestBody = {
        asset_ids: assetIds,
        account_ids: accountIds,
        workshop_ids: workshopIds,
    };
    removeUndefinedOrEmptyArrayAttributes(body);

    const fetchVehicleStatusGlobalCounters = () => {
        if (ignoreFetch) {
            setIgnoreFetch(false);
        } else {
            setIsLoading(true);
            axios
                .post<ResponseBody>(url, body)
                .then((response) => {
                    setGlobalCounters(response.data);
                    setIsLoading(false);
                    setLastUpdateTimestamp(new Date());
                })
                .catch((error) => {
                    console.error('Error fetching get-vehicle-status-global-counters', error);
                    setHasErrors(true);
                });
        }
    };

    useEffect(() => {
        if (newLocationId !== 'VEHICLE_STATUS') {
            // disable 'background' fetches whenever we are not on vehicle-status page
            return;
        }
        fetchVehicleStatusGlobalCounters();
    }, [reloadState, assetIds, accountIds, workshopIds]);

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
            return;
        }
        if (newLocationId !== 'VEHICLE_STATUS') {
            return;
        }
        if (oldLocationId === 'DETAIL_VIEW') {
            showPageRefreshNotification(lastUpdateTimestamp);
            return;
        }
        fetchVehicleStatusGlobalCounters();
    }, [newLocationId]);

    return {
        isLoading,
        hasErrors,
        globalCounters,
        triggerReload,
        lastUpdateTimestamp,
    };
};

const showPageRefreshNotification = (lastUpdateTimestamp: Date | undefined) => {
    sendMessage({
        type: SHOW_REFRESH_PAGE_NOTIFICATION,
        payload: {
            lastUpdateDate: lastUpdateTimestamp,
        },
    });
};

export default useFetchVehicleStatusGlobalCounters;
