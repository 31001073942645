import axios from 'axios';
import { useEffect, useState } from 'react';
import { config } from '../../config';
import { GetVehicleStatusData, VehicleStatusData } from '../../generated/GeneratedApiTypes';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getAssetTreeAccountIds, getAssetTreeAssetIds, getAssetTreeWorkshopIds } from '../app/assetTreeSlice';
import { isAssetTreeSelected, removeUndefinedOrEmptyArrayAttributes } from '../../utils/commonUtils';
import { Criticality, SyncStatus } from './FilterComponent';
import useSessionStorageState from '../../utils/useSessionStorageState';
import { getNewLocationId, getOldLocationId } from '../app/locationSlice';
import { VehicleStatusDataSectionKey, VehicleStatusDataSections } from './DataTabsSettingsDialogComponent';

const INITIAL_PAGE_NUMBER = 1;
const INITIAL_PAGE_SIZE = 60;

type RequestBody = GetVehicleStatusData.GetVehicleStatusData.RequestBody;
type ResponseBody = GetVehicleStatusData.GetVehicleStatusData.ResponseBody;

const vehicleStatusDataSections: VehicleStatusDataSectionKey[] = Object.values(VehicleStatusDataSections);

const useFetchVehiclesStatusData = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [ignoreFetch, setIgnoreFetch] = useState<boolean>(isAssetTreeSelected());
    const [reloadState, setReloadState] = useState<{}>({});
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
    const [vehiclesStatusData, setVehiclesStatusData] = useState<VehicleStatusData[]>([]);
    const [criticalityStatus, setCriticalityStatus] = useSessionStorageState<Criticality[]>(
        [],
        'vs-criticality-status',
    );
    const [syncStatus, setSyncStatus] = useSessionStorageState<SyncStatus[]>([], 'vs-sync-status');
    const [pageNumber, setPageNumber] = useState<number>(INITIAL_PAGE_NUMBER);
    const [selectedAssetId, setSelectedAssetId] = useState<string | undefined>(undefined);
    const [pageSize, setPageSize] = useSessionStorageState<number>(INITIAL_PAGE_SIZE, 'vs-page-size');
    const [totalPages, setTotalPages] = useState<number>(0);
    const [isCsvExportAllowed, setIsCsvExportAllowed] = useState<boolean>(false);
    const [selectedDataTabsSections, setSelectedDataTabsSections] = useSessionStorageState<
        VehicleStatusDataSectionKey[]
    >(vehicleStatusDataSections, 'vs-data-tabs-settings');

    // get values from asset tree
    const assetIds = useAppSelector(getAssetTreeAssetIds);
    const accountIds = useAppSelector(getAssetTreeAccountIds);
    const workshopIds = useAppSelector(getAssetTreeWorkshopIds);

    const oldLocationId = useAppSelector(getOldLocationId);
    const newLocationId = useAppSelector(getNewLocationId);

    const triggerReload = () => {
        setHasErrors(false);
        setIsLoading(true);
        setReloadState({});
    };

    const scrollToTop = () => {
        document.documentElement.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const url = `${config.backend.BACKEND_URI}/get-vehicle-status-data`;
    const body: RequestBody = {
        asset_ids: assetIds,
        account_ids: accountIds,
        workshop_ids: workshopIds,
        status: criticalityStatus,
        sync_status: syncStatus,
        page_number: pageNumber,
        page_size: pageSize,
        sections_to_include: selectedDataTabsSections,
    };
    removeUndefinedOrEmptyArrayAttributes(body);

    const fetchVehicleStatusData = () => {
        if (ignoreFetch) {
            setIgnoreFetch(false);
        } else {
            setIsLoading(true);
            axios
                .post<ResponseBody>(url, body)
                .then((response) => {
                    const { page_number, page_size, total_pages, is_csv_export_allowed, items } = response.data;
                    setPageNumber(page_number);
                    setPageSize(page_size);
                    setTotalPages(total_pages);
                    setIsCsvExportAllowed(is_csv_export_allowed);
                    setVehiclesStatusData(items);
                    setIsLoading(false);
                    setSelectedAssetId(undefined);
                    scrollToTop();
                })
                .catch((error) => {
                    console.error('Error fetching get-vehicle-status-data', error);
                    setHasErrors(true);
                });
        }
    };

    useEffect(() => {
        if (newLocationId !== 'VEHICLE_STATUS') {
            // disable 'background' fetches whenever we are not on vehicle-status page
            return;
        }
        // This useEffect forces pageNumber to 1 whenever one of the dependencies changes
        if (pageNumber !== INITIAL_PAGE_NUMBER) {
            setPageNumber(INITIAL_PAGE_NUMBER);
        } else {
            fetchVehicleStatusData();
        }
    }, [assetIds, accountIds, workshopIds, criticalityStatus, syncStatus, pageSize, selectedDataTabsSections]);

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
        } else {
            fetchVehicleStatusData();
        }
    }, [reloadState, pageNumber]);

    useEffect(() => {
        if (isFirstRender || oldLocationId === 'DETAIL_VIEW' || newLocationId !== 'VEHICLE_STATUS') {
            return;
        }
        // Go to page 1 and reload if user doesn't come from detail view
        if (pageNumber !== INITIAL_PAGE_NUMBER) {
            setPageNumber(INITIAL_PAGE_NUMBER);
        } else {
            fetchVehicleStatusData();
        }
    }, [newLocationId]);

    return {
        isLoading,
        hasErrors,
        vehiclesStatusData,
        pageSize,
        pageNumber,
        totalPages,
        isCsvExportAllowed,
        triggerReload,
        changeCriticalityStatus: setCriticalityStatus,
        changeSyncStatus: setSyncStatus,
        changePageNumber: setPageNumber,
        changePageSize: setPageSize,
        changeSelectedDataTabSections: setSelectedDataTabsSections,
        selectedDataTabsSections,
        criticalityStatus,
        syncStatus,
        setSelectedAssetId,
        highlightAssetId: oldLocationId === 'DETAIL_VIEW' ? selectedAssetId : undefined,
        clearHighlight: () => setSelectedAssetId(undefined),
    };
};

export default useFetchVehiclesStatusData;
