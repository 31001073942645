import React, { useEffect } from 'react';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { RootState } from '../../configuration/setup/store';
import { useAppDispatch } from '../../configuration/setup/hooks';
import { assetTreeSelectionChanged } from './assetTreeSlice';
import { locationSliceChanged } from './locationSlice';
import axios from 'axios';

// Events received from web-workshop
export const TOKEN_RENEWED = 'WEB_WORKSHOP/TOKEN_RENEWED';
export const ASSET_TREE_SELECTION_CHANGED = 'WEB_WORKSHOP/ASSET_TREE_SELECTION_CHANGED';
export const LOCATION_CHANGED = 'WEB_WORKSHOP/LOCATION_CHANGED';
export const FEATURE_TOGGLE_CHANGED = 'WEB_WORKSHOP/FEATURE_TOGGLE_CHANGED';

export const allowedLocalStorageIds = [''];

// Events sent to parent frontend (web-workshop)
export const VIEW_ASSET_DETAIL = 'VEHICLE_STATUS/VIEW_ASSET_DETAIL';
export const REQUEST_ASSET_TREE_SELECTION = 'VEHICLE_STATUS/REQUEST_ASSET_TREE_SELECTION';
export const SHOW_REFRESH_PAGE_NOTIFICATION = 'VEHICLE_STATUS/SHOW_REFRESH_PAGE_NOTIFICATION';
export const SHOW_NOTIFICATION = 'VEHICLE_STATUS/SHOW_NOTIFICATION';

// Enum to tell parent which detail page tab it should redirect to
// Disabling the no-shadow as this is a bug from eslint with typescript
// eslint-disable-next-line no-shadow
export const enum DetailPageTab {
    GENERAL = 'GENERAL',
    MAINTENANCE_DAMAGE = 'MAINTENANCE_DAMAGE',
    VEHICLE_INFO = 'VEHICLE_INFO',
    BRAKE_LININGS = 'BRAKE_LININGS',
    DTC_DM1 = 'DTC_DM1',
    DTC_DM4 = 'DTC_DM4',
    WARNING_MESSAGES = 'WARNING_MESSAGES',
    WORKSHOP_ACTIONS = 'WORKSHOP_ACTIONS',
}

interface MessageEvent<T> {
    type: string;
    payload: T;
}

interface FeatureToggleEvent {
    key: string;
    enabled: string;
}

export const sendMessage = (message: MessageEvent<any>) => {
    const referrer = document.referrer;
    if (referrer) {
        window.parent.postMessage(message, referrer);
    }
};

export const receiveMessage = (event: any, dispatch: ThunkDispatch<Dispatch<any>, RootState, any>) => {
    const actionType = event.data.type;
    if (!actionType) {
        return;
    }
    switch (actionType) {
        case TOKEN_RENEWED:
            const { token } = event.data.payload;
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            break;
        case ASSET_TREE_SELECTION_CHANGED:
            dispatch(assetTreeSelectionChanged(event.data.payload));
            break;
        case LOCATION_CHANGED:
            dispatch(locationSliceChanged(event.data.payload));
            break;
        case FEATURE_TOGGLE_CHANGED:
            handleFeatureToggle(event.data.payload);
            break;
        default:
            // eslint-disable-next-line
            console.log('Received event in vehicle-status with type not handled: ', actionType);
            break;
    }
};

const handleFeatureToggle = (payload: FeatureToggleEvent) => {
    if (allowedLocalStorageIds.includes(payload.key)) {
        setItemInLocalStorage(payload);
    }
};

const setItemInLocalStorage = (payload: FeatureToggleEvent) => {
    const { key, enabled } = payload;
    if (enabled) {
        localStorage.setItem(key, enabled);
    } else {
        localStorage.removeItem(key);
    }
};

// eslint-disable-next-line react/display-name
export const MessageHandler = React.memo((props) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        const listenerCallback = (event: any) => receiveMessage(event, dispatch);
        window.addEventListener('message', listenerCallback, false);
        return () => window.removeEventListener('message', listenerCallback, false);
    }, []);

    return null;
});

export default MessageHandler;
